<!--
 * @Descripttion: js
 * @Version: 1.0
 * @Author: name
 * @Date: 2023-06-30 16:39:07
 * @LastEditors: name
 * @LastEditTime: 2023-10-24 17:13:08
-->
<template>
  <div class="basic-info"
       style="margin-bottom: 50px"
       v-loading="loading"
       element-loading-text="内容加载中...">
    <div v-if="type != 'platform' && detailInfo.authenticateState != '3'">
      <el-steps :active="stepActive"
                align-center
                process-status="finish"
                style="margin: 47px 0 67px 0">
        <el-step v-for="(item, index) of stepsList"
                 :key="index"
                 :title="item">
          <div slot="icon">
            <!-- v-if="type != 'platform' && detailInfo.authenticateState != '3'" -->
            <i class="el-icon-s-opportunity"></i>
            <!-- @click="stepsChange(index)" -->
            <!-- <span
              style="display:inline-block;width:12px;height:12px;border-radius:50%;background:#4963f4;position:relative;top:2px;"
            ></span>-->
          </div>
        </el-step>
      </el-steps>
      <div v-if="isBegin">
        <!-- <div v-if="stepActive === 0">
        <AdminAdd :stepActive.sync="stepActive"></AdminAdd>
          <el-card>
            <div style="display: flex; justify-content: space-between;margin: 20px">
              <el-select
                style="width:400px"
                v-model="value"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请选择管理员姓名"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="adminAdd"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.code"
                  :label="item.name + '-' + item.idCardNumber"
                  :value="item.code"
                ></el-option>
              </el-select>
              <el-button type="primary" @click="toNext" style="margin-left: 10px">确定</el-button>
            </div>
            <div style="text-align: center">
              <el-button type="primary" @click="toNext" :disabled="!isAuthenticated">下一步</el-button>
            </div>
          </el-card>
        </div>-->
        <div v-if="stepActive === 0">
          <EnterpriseInfoAdd :stepActive.sync="stepActive"
                             :admin.sync="admin"></EnterpriseInfoAdd>
        </div>
        <div v-if="stepActive === 1">
          <EnterpriseInfoAdd :stepActive.sync="stepActive"></EnterpriseInfoAdd>
        </div>
        <!-- <div v-if="stepActive === 2">
          <EnterpriseInfoAdd :stepActive.sync="stepActive"></EnterpriseInfoAdd>
        </div>-->
        <div v-if="stepActive === 2">
          <AmountCheck :stepActive.sync="stepActive"></AmountCheck>
        </div>
        <div v-if="stepActive === 3">
          <el-card>
            <div style="margin-top: 49px">
              <img :src="require('../../../assets/complete.png')"
                   alt />
            </div>
            <div class="succse_class">企业认证已完成！</div>
            <div style="font-size: 14px; color: #5b5b5b; margin-top: 23px">现跳转至后台管理页面</div>
            <div style="margin-top: 55px; margin-bottom: 80px">
              <span style="font-size: 14px; color: #5b5b5b">无法跳转？点击</span>
              <el-button size="mini"
                         type="primary"
                         style="margin-left: 16px"
                         v-prev
                         path="/system/index/home">返回首页</el-button>
            </div>
          </el-card>
        </div>
      </div>
      <el-card class="box-card"
               v-if="!isBegin">
        <div class="desc"
             style="margin-top: 16px">您还未完成实名认证</div>
        <div class="desc"
             style="margin-bottom: 52px">现在通过实名认证，您将可以：</div>
        <div style="display: flex; justify-content: space-around">
          <img src="../../../assets/step1.svg"
               alt
               style="width: 193px; height: 182px" />
          <img src="../../../assets/step2.svg"
               alt
               style="width: 193px; height: 182px" />
          <img src="../../../assets/step3.svg"
               alt
               style="width: 193px; height: 182px" />
        </div>
        <el-steps :active="0"
                  align-center
                  process-status="wait"
                  style="margin-top: 53px">
          <el-step title="实名认证是业务合规第一步">
            <div slot="icon">
              <i class="el-icon-check"></i>
            </div>
          </el-step>
          <el-step title="实名认证企业才能进行业务办理">
            <div slot="icon">
              <i class="el-icon-check"></i>
            </div>
          </el-step>
          <el-step title="实名认证企业才能体验平台全部功能">
            <div slot="icon">
              <i class="el-icon-check"></i>
            </div>
          </el-step>
        </el-steps>
        <div class="desc_footer">
          请提前准备好电子版
          <span class="font-color-main">管理员身份证，企业营业执照，企业法人身份证</span>
          和
          <span class="font-color-main">企业开户行信息</span>，然后开始认证流程
        </div>
        <el-button type="primary"
                   @click="isBegin = true">立即认证</el-button>
      </el-card>
    </div>
    <div v-if="type === 'platform' || detailInfo.authenticateState === '3'">
      <div class="header">
        <div class="header_top">
          <img src="../../../assets/user.png"
               alt />
          <span>人资管理</span>
          <img src="../../../assets/right.png"
               alt />
          <span>企业信息</span>
        </div>
      </div>
      <div class="text item">
        <div v-if="detailInfo.name">
          <div class="edit_border">
            <div class="edit_top">
              <el-card class="border_left">
                <div class="left_top">
                  <div class="left_icon">
                    <span></span>
                    <span>基础信息</span>
                  </div>
                </div>
                <div class="left-demo">
                  <div class="left-demo_left">
                    <img src="../../../assets/jcxx-1.png"
                         alt />
                    <span>企业名称</span>
                  </div>
                  <span class="left-demo_right">{{ detailInfo.name }}</span>
                </div>
                <div class="left-demo">
                  <div class="left-demo_left">
                    <img src="../../../assets/jcxx-2.png"
                         alt />
                    <span>统一信用编码</span>
                  </div>
                  <span class="left-demo_right">{{ detailInfo.idCardNumber }}</span>
                </div>
                <div class="left-demo">
                  <div class="left-demo_left">
                    <img src="../../../assets/jcxx-3.png"
                         alt />
                    <span>营业执照照片</span>
                  </div>
                  <div style="display: flex; align-items: center">
                    <el-tooltip class="item"
                                effect="dark"
                                content="点击查看图片"
                                placement="top-start">
                      <i class="el-icon-picture-outline font-img"
                         @click="showImg(detailInfo.idCardImage, '查看照片')"></i>
                    </el-tooltip>
                    <el-tag type="success"
                            style="margin-left: 10px">已上传</el-tag>
                  </div>
                </div>
                <div class="left-demo">
                  <div class="left-demo_left">
                    <img src="../../../assets/jcxx-4.png"
                         alt />
                    <span>经营场所</span>
                  </div>
                  <span class="left-demo_right">{{ detailInfo.address }}</span>
                </div>
                <div class="left-demo">
                  <div class="left-demo_left">
                    <img src="../../../assets/jcxx-5.png"
                         alt />
                    <span>认证状态</span>
                  </div>
                  <span class="left-demo_right">{{ detailInfo.authenticateState | enterpriseCertiState }}</span>
                </div>
                <div class="left_buttom">
                  <img src="../../../assets/jcxx-6.png"
                       alt />
                </div>
              </el-card>
              <div class="border_right">
                <el-card class="right_one">
                  <div class="left_top">
                    <div class="left_icon">
                      <span></span>
                      <span>法人信息</span>
                    </div>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/frxx-1.svg"
                           alt />
                      <span>法人姓名</span>
                    </div>
                    <span class="left-demo_right">{{ detailInfo.juridicalPersonName }}</span>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/frxx-2.png"
                           alt />
                      <span>法人身份证号</span>
                    </div>
                    <span class="left-demo_right">{{ detailInfo.juridicalPersonId }}</span>
                  </div>
                  <el-row>
                    <el-col :span="10"
                            :offset="2">
                      <el-card :body-style="{
                        padding: '0px',
                        width: '300px',
                        height: '200px',
                      }"
                               class="card">
                        <img :src="detailInfo.juridicalPersonIdFont"
                             style="width: 100%; height: 100%" />
                      </el-card>
                    </el-col>
                    <el-col :span="10"
                            :offset="2">
                      <el-card :body-style="{
                        padding: '0px',
                        width: '300px',
                        height: '200px',
                      }">
                        <img :src="detailInfo.juridicalPersonIdBack"
                             style="width: 100%; height: 100%" />
                      </el-card>
                    </el-col>
                  </el-row>
                </el-card>

                <el-card class="right_one">
                  <div class="left_top">
                    <div class="left_icon">
                      <span></span>
                      <span>开户信息</span>
                    </div>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/khxx-1.png"
                           alt />
                      <span>开户银行</span>
                    </div>
                    <span class="left-demo_right"
                          v-if="detailInfo.bankCard">{{ detailInfo.bankCard.bankName }}</span>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/khxx-2.png"
                           alt />
                      <span>银行卡号</span>
                    </div>
                    <span class="left-demo_right"
                          v-if="detailInfo.bankCard">
                      {{
                      detailInfo.bankCard.accountCode
                      }}
                    </span>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/khxx-3.png"
                           alt />
                      <span>开户地</span>
                    </div>
                    <span class="left-demo_right"
                          v-if="detailInfo.bankCard">{{ detailInfo.bankCard.bankArea }}</span>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/khxx-4.png"
                           alt />
                      <span>支行</span>
                    </div>
                    <span class="left-demo_right"
                          v-if="detailInfo.bankCard">{{ detailInfo.bankCard.branchBank }}</span>
                  </div>
                  <div class="left-demo">
                    <div class="left-demo_left">
                      <img src="../../../assets/khxx-5.png"
                           alt />
                      <span>支行联行号</span>
                    </div>
                    <span class="left-demo_right"
                          v-if="detailInfo.bankCard">{{ detailInfo.bankCard.branchCode }}</span>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </div>
        <MybankList :viewVisable.sync="viewVisable"
                    :userCode="code"
                    @viewVisable="viewVisableShow"></MybankList>
        <!-- <AdminList v-if="isShow" ref="mychild" @childFn="oldCodeShow" :userCode="code"></AdminList> -->
      </div>
    </div>
    <!-- 查看图片 -->
    <el-dialog class="showImgDialog"
               width="680px"
               :center="true"
               :title="dialogTitle"
               :visible.sync="showImgVisible"
               :append-to-body="true">
      <div class="imgBox">
        <img :src="dialogImgUrl"
             class="img-resource"
             alt />
      </div>
    </el-dialog>
    <!-- 充值指导信息 -->
    <el-dialog title="充值指导信息"
               :visible.sync="viewVisable"
               :append-to-body="true"
               width="500px">
      <h3>专属账户信息</h3>
      <el-row>
        <el-col :span="24"
                class="text-align">帐户户名：{{ bankInfo.platform_name }}</el-col>
        <el-col :span="24"
                class="text-align">帐户编号：{{ bankInfo.sub_account }}</el-col>
        <el-col :span="24"
                class="text-align">结算账户余额：{{ bankInfo.balance | money }}元</el-col>
      </el-row>
      <h3 style="margin-top: 30px">充值指导信息</h3>
      <el-row>
        <el-col :span="24"
                class="text-align">收款户名：{{ bankInfo.platform_name }}</el-col>
        <el-col :span="24"
                class="text-align">收款账号：{{ bankInfo.account }}</el-col>
        <el-col :span="24"
                class="text-align">收款银行：{{ bankInfo.bankname }}</el-col>
        <el-col :span="24"
                class="text-align">收款支行：{{ bankInfo.branchBank }}</el-col>
        <el-col :span="24"
                class="text-align">联行号：{{ bankInfo.bank_line_no }}</el-col>
      </el-row>
    </el-dialog>
    <Divider title="企业管理员信息"
             :tag="`企业管理员必须在公众号上经过员工实名认证`">
      <span slot="right">
        <el-button type="text"
                   @click="adminDialogVisible = true"
                   v-if="admin">更换企业管理员</el-button>
        <el-button type="text"
                   @click="adminDialogVisible = true"
                   v-else>设置企业管理员</el-button>
      </span>
    </Divider>
    <el-descriptions title="管理员信息"
                     v-if="admin">
      <el-descriptions-item label="用户名">
        {{
        admin.name
        }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">
        {{
        admin.username
        }}
      </el-descriptions-item>
    </el-descriptions>
    <!-- 更换企业管理员 -->
    <EnterpriseAdminChange :dialogVisible.sync="adminDialogVisible"
                           :admin.sync="admin"
                           :code="oldCode"
                           @show="dialogVisibleVal"
                           @close="getEnterpriseInfo"></EnterpriseAdminChange>
  </div>
</template>
<script>

import { api } from '/src/api/base';
export const enterpriseDescribe = api()('enterprise.describe.json');
export const customerInfo = api()('enterprise.info.json');

export const userChangeEnterpriseAdmin = api()(
  'user.change.enterprise.admin.json'
);
// export const bankCardList = api()('bank.card.list.json');
export const userDescribe = api()('user.describe.json');

export default {
  components: {
    Divider: () => import('../../../components/divider.vue'),
    MybankList: () => import('./myBankInfo.vue'),
    AmountCheck: () => import('./amountCheck.vue'),
    EnterpriseAdminChange: () => import('./enterpriseAdminChange.vue'),
    // AdminList: () => import('./adminList.vue'),
    // AdminAdd: () => import('./adminAdd.vue'),
    EnterpriseInfoAdd: () => import('./enterpriseInfoAdd.vue'),

  },
  data () {
    return {
      loading: false,
      code: '',
      oldCode: '',
      value: '',
      admin: {},
      userList: [],
      userInfo: [],
      IsAmin: false,
      detailInfo: {
        code: '',
        name: '',
        idCardNumber: '',

        idCardImage: '',

        juridicalPersonName: '',

        juridicalPersonId: '',

        juridicalPersonIdFont: '',

        juridicalPersonIdBack: '',

        esignAccount: '',

        esignFlowId: '',

        mybankAccount: '',

        bankName: '',

        bankCode: '',

        bankArea: '',

        bankBranch: '',

        bankBranchCode: '',

        smsLimit: '',

        contractLimit: '',

        makerVertify: '',

        authenticateState: '',

        createTime: ''
      },
      dialogTitle: '',
      showImgVisible: false,
      dialogImgUrl: '',
      isShow: false,
      viewVisable: false,
      bankInfo: {},
      // refreshLoading: false,
      adminDialogVisible: false,

      stepsList: [
        // '企业管理员认证',
        '企业营业执照填写',
        '企业法人信息填写',
        // '企业开户信息填写',
        '企业认证',
        // '对公打款认证',
        '企业认证完成'
      ],
      isBegin: true,
      stepActive: 0,
      type: '',
      isAuthenticated: false
    };
  },

  async created () {
    this.loading = true;

    this.IsAdmin = window.localStorage.getItem('isEnterpriseSuperAdmin') || false
    console.log(this.IsAdmin, typeof (this.IsAdmin));
    this.code =
      this.$route.query.code ||
      window.localStorage.getItem('enterprise') ||
      window.localStorage.getItem('enterprise');
    this.type = this.$route.query.type;
    this.isShow = this.$route.query.isShow ? false : true

    await this.getUserInfo();
    await this.getInfo();
    await this.getEnterpriseInfo()
  },
  methods: {
    dialogVisibleVal (params) {
      this.dialogVisible = params
    },
    async getEnterpriseInfo () {
      const res = await customerInfo({
        code: localStorage.getItem('enterprise'),

      });
      this.admin = res.user
    },
    async getUserInfo () {
      const res = await userDescribe({
        code: localStorage.getItem('code'),
        $ignoreRepeat: true
      });
      this.userInfo = res
      this.isAuthenticated = res.isAuthenticated;
    },
    // 扮演

    // 更换管理员

    // 更新网商余额

    // 查询管理员列表

    // 网商帐户列表

    // 查看网商帐户信息
    oldCodeShow (data) {
      this.oldCode = data
      this.adminDialogVisible = true;
    },
    viewVisableShow (data) {
      console.log(data);
      this.bankInfo = data
      this.viewVisable = true
    },
    getAdminInfo () {
      this.$refs.mychild.getAdminInfo();
    },
    async getInfo () {
      const result = await enterpriseDescribe({
        code: this.code,
        $ignoreRepeat: true
      });
      this.detailInfo = result;
      // const res = await bankCardList({
      //   subject: this.code,
      //   isDefault: true
      // });
      // this.detailInfo.bankCard = res.list[0] || {};
      this.loading = false;
    },
    goBack () {
      window.history.go(-1);
    },
    async getEnterpruseriseInfo () {
      const res = await customerInfo({
        code: localStorage.getItem('enterprise'),

      });
      this.admin = res.user
    },
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      this.showImgVisible = true;
    },

    async stepsChange (i) {
      this.stepActive = i;
      if (i === 0) {
        await this.getUserInfo();
      } else {
        await this.getInfo();
      }
      if (i === 4 && this.detailInfo.authenticateState != '2') {
        this.$message.error('未发起打款');
        return;
      }
      if (i === 1) {
        // this.$message.error('请先完成管理员认证！');
        return;
      }
      if (i === 5) return;
    }
  }
};
</script>

<style lang="scss" scoped>
.basic-info {
  background-color: #fff !important;
}

.header {
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
}

.header_top {
  display: flex;
  align-items: center;

  img:nth-child(1) {
    width: 20px;
    height: 17px;
  }

  img:nth-child(2) {
    width: 9px;
    height: 10px;
  }

  span {
    margin: 0 10px;
    font-size: 16px;
    color: #606060;
  }
}

.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}

.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}

.showImgDialog .imgBox {
  display: flex;
}

.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}

.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}

.card-title {
  margin-right: 8px;
}

.card-btn {
  cursor: pointer;
}

.text {
  font-size: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}

.text-align_avi {
  text-align: right;
  line-height: 32px;
  padding-bottom: 8px;
}

.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marginBottom {
  margin-bottom: 12px;
}

.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}

.aType {
  color: #659fe5;
  cursor: pointer;
}

.tooltipClas {
  width: 250px;
}

.basic-info {
  .warning {
    color: #e6a23c;
  }

  .success {
    color: #67c23a;
  }

  .danger {
    color: #f56c6c;
  }

  .info {
    color: #409eff;
  }
}

.defaultTips {
  display: inline-block;
  margin: 0 8px;

  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}

.invite-box {
  margin: 40px auto 20px auto;
  text-align: center;
}

h3 {
  margin: 20px 0;
}

.edit_border {
  border-radius: 20px;
  background: #fff;
  padding: 20px;
  width: 100%;

  .edit_top {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    padding: 20px;
    width: 100%;

    .border_left {
      width: 48%;

      // flex-direction: column;
      // display: flex;
      .left_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        .left_icon {
          display: flex;
          align-items: center;

          span:nth-child(1) {
            padding: 8px 4px;
            background: #4977fc;
            margin-right: 10px;
          }

          span:nth-child(2) {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .left-demo {
        justify-content: space-between;
        display: flex;
        align-items: center;
        padding: 20px 10px;
        border-bottom: 1px dashed #000;
        font-size: 15px;

        .left-demo_left {
          display: flex;
          align-items: center;

          img {
            width: 39px;
            height: 35px;
          }

          span {
            margin-left: 15px;
          }
        }

        .left-demo_right {
          color: #b4b4b4;
        }
      }

      .left_buttom {
        margin: 140px 0 0 0;
        height: 100%;
        flex: 1;
      }

      // .left-demo:last-child {
      //   border: none;
      // }
    }

    .border_right {
      width: 48%;
      display: flex;
      flex-wrap: wrap;

      border-radius: 10px;

      .right_one {
        width: 100%;
        padding: 10px;
        border-radius: 10px;

        .left_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;

          .left_icon {
            display: flex;
            align-items: center;

            span:nth-child(1) {
              padding: 8px 4px;
              background: #4977fc;
              margin-right: 10px;
            }

            span:nth-child(2) {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }

        .left-demo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 10px;
          color: #b4b4b4;
          font-size: 15px;

          .left-demo_left {
            display: flex;
            align-items: center;
            color: #000;

            img {
              width: 20px;
              height: 23px;
            }

            span {
              margin-left: 15px;
            }
          }
        }
      }

      .right_one:nth-child(2) {
        margin: 20px 0 0 0;

        .left-demo {
          border-bottom: 1px dashed #000;
        }

        .left-demo:last-child {
          border: none;
        }
      }
    }
  }
}

.desc {
  text-align: left;
  font-size: 12px;
  color: #545454;
  line-height: 24px;
}

.desc_footer {
  font-size: 12px;
  color: #545454;
  margin: 76px 0;
}
.succse_class {
  font-size: 24px;
  color: #4e4e4e;
  font-weight: bold;
  margin-top: 45px;
}
</style>
